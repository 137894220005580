exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boarding-js": () => import("./../../../src/pages/boarding.js" /* webpackChunkName: "component---src-pages-boarding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-show-jumping-lessons-js": () => import("./../../../src/pages/show-jumping-lessons.js" /* webpackChunkName: "component---src-pages-show-jumping-lessons-js" */),
  "component---src-pages-stalls-js": () => import("./../../../src/pages/stalls.js" /* webpackChunkName: "component---src-pages-stalls-js" */),
  "component---src-pages-the-arena-js": () => import("./../../../src/pages/the-arena.js" /* webpackChunkName: "component---src-pages-the-arena-js" */)
}

